import buttons from './buttons'

export default {
  // ? ==========================
  // ? =====  Multilocation  ====
  // ? ==========================

  multiLocation: {},

  // ? ===================
  // ? =====  Layout  ====
  // ? ===================

  navigation: {}, //! shadowed

  footer: {
    position: 'relative',
    backgroundColor: 'primary',
    color: 'white',
    '.hoursContainer': {
      display: 'none!important'
    },
    '.logo': {
      filter: 'brightness(0) invert(1)',
      marginBottom: '1rem',
      position: 'relative',
      top: ['', '', '', '-1rem']
    },
    '.logoColumn': {
      border: 'none !important'
    },
    '.mapColumn': {
      width: ['100%', '', '', '50%'],
      maxWidth: 'unset',
      iframe: {
        width: '100%',
        height: '400px',
        border: 'none',
        boxShadow: '-7px 7px #f58345'
      }
    },
    '.multiButtonContainer': {
      flexDirection: ['row', '', '', 'column'],
      a: {
        color: 'white'
      }
    },
    '.contactDetails-container .text': {
      fontSize: ['1rem', '1.25rem']
    }
  },

  ctaWidget: {
    display: 'none!important'
  },

  // ? ======================
  // ? ====  reuseables  ====
  // ? ======================

  title: {
    fontFamily: 'heading',
    color: 'brandBlue',
    textTransform: 'capitalize',
    marginBottom: '2rem',
    fontSize: ['1.75rem', '', '1.85rem', '2rem'],
    order: '1'
  },
  subtitle: {
    // fontFamily: 'subheading',
    fontFamily: 'Open Sans',
    color: 'brandOrange',
    border: 'none',
    order: '2',
    fontWeight: 900
  },
  text: {
    fontFamily: 'body',
    color: '#76a2a9',
    order: '3'
  },

  ctaLink: {
    order: '4'
  },

  // ? ====================
  // ? ====  Homepage  ====
  // ? ====================

  homepageHero: {
    // '.innerContainer': {
    backgroundPosition: 'bottom center',
    // },
    '.muteToggle': {
      right: '1rem',
      top: '100px'
    },
    '.hero_content_container': {
      margin: '1rem 0rem',
      border: 'none',
      width: '100%',
      '.title': {
        fontSize: ['2rem', '3rem', '3.5rem', '4rem', '4.5rem'],
        textTransform: 'capitalize',
        fontWeight: '200',
        border: 'none',
        padding: '0rem',
        maxWidth: ['', '', '', '50%']
      },
      a: {
        position: ['', '', '', 'absolute'],
        right: '0rem',
        bottom: '2rem'
      },
      '.ctaButton': {
        variant: 'buttons.primary',
        maxWidth: '400px'
      }
    }
  },

  homepageHeroShout: {},

  homepageShout: {
    padding: ['1rem', '1rem', '3rem 2rem 12rem'],
    // marginBottom: '7rem',
    position: 'relative',
    // overflow: 'hidden',
    // zIndex: ['', '', '-1'],
    flexDirection: ['column', 'column', 'row'],
    '.imageContainer': {
      maxHeight: ['', '', '50vh'],
      position: ['', '', 'relative'],
      width: ['100%', '100%', '50%'],
      top: '1rem',
      left: ['0rem', '', '2rem', '6rem'],
      order: ['2', '', '1'],
      borderLeft: ['', '', 'solid 6px'],
      borderColor: ['', '', 'primary']
    },

    '.content': {
      position: ['', '', 'relative'],
      width: ['100%', '100%', '50%'],
      top: '6rem',
      left: '0rem',
      backgroundColor: '#e0f4fd',
      border: 'solid 7px',
      boxShadow: ['', '', '-20px -19px 0px 0px white'],
      borderColor: 'brandOrange',
      padding: ['', '', '', '2rem 5rem'],
      alignItems: 'flex-start',
      textAlign: 'left',
      order: ['1', '', '2']
    },
    '.title': {
      fontFamily: 'subheading',
      margin: '1rem 0rem',
      textAlign: 'left',
      fontSize: ['1.5rem', '', '2.5rem'],
      fontWeight: 'bold',
      color: 'brandOrange',
      position: ['', '', '', 'absolute'],
      left: ['', '', '', '-105%'],
      top: ['', '', '', '0%'],
      writingMode: ['', '', '', 'vertical-lr'],
      transform: ['', '', '', 'rotate(180deg)'],
      transformOrigin: ['', '', '', 'center'],
      width: ['', '', '', 'fit-content'],
      height: ['', '', '', 'fit-content']
    },
    '.date': {
      margin: '1rem 0rem',
      textAlign: 'left'
    },
    '.text': {
      fontFamily: 'heading',
      color: 'brandBlue',
      fontWeight: 'bold',
      margin: '1rem 0rem',
      textAlign: 'left'
    },
    '.shoutCTASBtns': {
      width: '100%'
    },

    '.shoutCTA': {
      variant: 'buttons.primary',
      padding: '1rem'
    }
  },

  homepageAboutSlider: {
    backgroundColor: 'background',
    margin: '0rem',
    padding: ['', '', '4rem 0rem'],
    backgroundImage: "url('https://res.cloudinary.com/gonation/image/upload/v1622126243/sites/kokomos/pineapple.png')",
    backgroundRepeat: 'no-repeat',
    backgroundSize: ['30%', '', '25%'],
    backgroundPosition: ['top left', '', 'left center'],
    '.hero_content_container': {
      margin: ['', '', '0rem 0rem 6rem auto', '', '0rem 2rem 6rem auto'],
      position: 'static',
      padding: ['2rem 1rem', '', '0rem 1rem', '0rem 1rem 0rem 3rem', '0rem 1rem 0rem 5rem'],
      borderLeft: ['', '', '3px solid'],
      borderColor: ['', '', 'brandOrange']
    },
    '.title': {
      variant: 'customVariants.title',
      fontSize: ['2rem', '', '2.25rem', '2.5rem', '2.5rem', '3rem'],
      paddingLeft: ['', '', '1rem', '2rem', '3rem'],
      display: 'flex',
      border: ['', '', 'none'],
      alignItems: 'center',
      left: '0rem',
      top: '4rem',
      lineHeight: '1.5',
      marginBottom: ['1.5rem', '', '0rem'],
      maxWidth: 'unset',
      width: ['100%', '', '40%']
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      fontSize: ['1.75rem', '', '2rem'],
      whiteSpace: ['', '', '', 'nowrap'],
      fontWeight: 900
    },
    '.text': {
      variant: 'customVariants.text',
      // textTransform: 'uppercase',
      fontSize: ['1rem', '', ''],
      color: '#76a2a9'
    },
    a: {
      order: '4',
      '.ctaButton': {
        variant: 'buttons.primary'
      }
    },

    '.slick-slider': {
      borderLeft: ['', '', '10px solid'],
      borderColor: ['', '', 'brandPink'],
      width: ['100%', '', '', '80%'],
      left: ['', '', '', '20%'],
      top: ['', '', '2rem'],
      '.slick-slide': {
        img: {
          filter: 'brightness(1)'
        }
      },
      '.slick-prev': {
        backgroundColor: 'secondary'
      },
      '.slick-next': {
        backgroundColor: '#bcd1e7',
        color: 'brandBlue'
      }
    }
  },

  homepageAbout: {
    '.lazyload-wrapper': {
      minHeight: ['', '', '', '70vh']
    },
    '.content': {
      // order: ['', '', '', '2'],
      padding: ['1rem', '', '', '1rem 6rem 1rem 6rem']
    },
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.text': {
      variant: 'customVariants.text',
      h3: {
        color: 'brandPink'
      }
    },
    a: {
      variant: 'customVariants.ctaLink'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    },
    '.image': {
      maxHeight: '600px'
    }
  },

  homepageMenu: {},

  homepageGallery: {
    '.section': {
      justifyContent: 'center'
    },
    a: {
      variant: 'customVariants.ctaLink'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  contentSection: {
    variant: 'contentSectionV1a',
    // display: 'none'
    '::before': {
      width: '100%'
    },
    textAlign: 'center',
    borderBottom: '2px solid white'
  },

  homepageSliderHero: {
    backgroundColor: 'background',
    margin: '0rem',
    padding: ['', '', '4rem 0rem'],
    backgroundImage:
      "url('https://res.cloudinary.com/gonation/image/upload/v1622583615/sites/kokomos/Kokomos-Beach-Club-tree.png')",
    backgroundRepeat: 'no-repeat',
    backgroundSize: ['30%', '', '25%'],
    backgroundPosition: ['top left', '', 'left center'],
    '.hero_content_container': {
      margin: ['', '', '0rem 0rem 6rem auto', '', '0rem 2rem 6rem auto'],
      position: 'static',
      padding: ['2rem 1rem', '', '0rem 1rem', '0rem 1rem 0rem 3rem', '0rem 1rem 0rem 5rem'],
      borderLeft: ['', '', '3px solid'],
      borderColor: ['', '', 'brandOrange']
    },
    '.title': {
      variant: 'customVariants.title',
      fontSize: ['2rem', '', '2.25rem', '3rem', '3rem', '4rem'],
      paddingLeft: ['', '', '1rem', '2rem', '3rem'],
      display: 'flex',
      border: ['', '', 'none'],
      alignItems: 'center',
      left: '0rem',
      top: '4rem',
      lineHeight: '1.5',
      marginBottom: ['1.5rem', '', '0rem'],
      maxWidth: 'unset',
      width: ['100%', '', '40%']
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      fontSize: ['1.75rem', '', '2rem'],
      whiteSpace: ['', '', '', 'nowrap'],
      fontWeight: 'bold'
    },
    '.text': {
      variant: 'customVariants.text',
      fontSize: ['1.5rem', '', '2rem'],
      fontWeight: 'bold',
      textTransform: 'uppercase',
      letterSpacing: '2px',
      fontFamily: 'heading',
      color: '#e5be01',
      marginBottom: '1.5rem'
    },
    a: {
      order: '4',
      '.ctaButton': {
        variant: 'buttons.primary'
      }
    },

    '.slick-slider': {
      borderLeft: ['', '', '10px solid'],
      borderColor: ['', '', 'brandPink'],
      width: ['100%', '', '', '80%'],
      left: ['', '', '', '20%'],
      top: ['', '', '2rem'],
      '.slick-slide': {
        img: {
          filter: 'brightness(1)'
        }
      },
      '.slick-prev': {
        backgroundColor: 'secondary'
      },
      '.slick-next': {
        backgroundColor: '#bcd1e7',
        color: 'brandBlue'
      }
    }
  },
  homepageReservations: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    marginLeft: ['', '1rem', '3rem', '8rem'],
    justifyContent: 'center',
    alignItems: 'center',
    padding: ['3rem 1rem', '', '6rem 1rem'],
    flexDirection: 'row',
    justifyContent: 'space-around',
    backgroundImage:
      'url(https://res.cloudinary.com/gonation/image/upload/v1622126290/sites/kokomos/kokomos-logo_symbol_NO_Text.png.png)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: ['22.5%'],
    backgroundPosition: ['1% 12.5%', '', 'center left'],

    // flexDirection: 'column',
    '.textContent': {
      marginBottom: '2rem',
      textAlign: 'center',
      flexDirection: 'column',
      paddingLeft: ['15%', '', '25%', '25%'],
      width: ['85%', '', '70%']
    },
    '.title': {
      fontSize: ['1.25rem', '1.5rem', '2rem', '2.5rem'],
      fontWeight: '400',
      color: 'brandOrange',
      textAlign: 'left',

      borderLeft: '3px solid',
      borderColor: 'brandPink',
      padding: ['1rem', '', '2rem']
    },
    '.text': {
      textAlign: 'left',
      paddingLeft: '1rem',
      marginTop: '1rem'
    },
    '.lazyload-wrapper': {
      display: 'none'
    },
    '.ctaButton': {
      variant: 'buttons.primary',
      height: 'fit-content'
    }
  },

  specials: {
    variant: 'customVariants.homepageReservations',
    backgroundImage: 'none',
    '.title': {
      fontSize: ['1.25rem', '1.5rem', '2rem', '2.5rem'],
      fontWeight: '400',
      color: 'brandOrange',
      textAlign: 'left',
      padding: ['1rem', '', '2rem'],
      border: 'none'
    },
    img: {
      display: 'none'
    }
  },

  homepageMerchandise: {
    variant: 'customVariants.homepageAbout',

    '.content': {
      width: ['', '', '50%'],
      '::before': {
        content: "''",
        backgroundImage:
          'url(https://res.cloudinary.com/gonation/image/upload/v1622126290/sites/kokomos/5a97cc813589288b9964df54ccbc9728.png)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundPosition: 'right top',
        height: '175px',
        width: '200px',
        marginBottom: '2rem'
      }
    },
    '.imageContainer': { width: ['', '', '50%'] }
  },

  homepageContactUs: {
    '.section': {
      justifyContent: 'center'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  // ? ========================
  // ? =====  About page  =====
  // ? ========================

  aboutSection: {
    flexDirection: ['column', 'column', 'column', 'row'],
    padding: ['1rem', , '2rem'],
    '.lazyload-wrapper': {
      width: ['100%', '100%', '100%', '30%']
    },
    '.title': {
      color: 'grey',
      fontWeight: '400'
    },
    '.subtitle': {
      textTransform: 'uppercase',
      fontWeight: 'bold',
      letterSpacing: '2px',
      fontSize: '2rem',
      marginBottom: '2rem'
    },
    '.content': {
      order: ['', '', '', '1'],
      border: 'none',
      borderWidth: '5px',
      padding: ['1rem 0rem', , 8],
      width: ['100%', '100%', '100%', '68%'],
      margin: ['', '', '', 'auto 0 0']
    },

    // '.lazyload-wrapper::after': {
    //   content: "'Photo (c) Felix Angel'",
    // },
    '.image': {
      order: ['', '', '2'],
      width: ['100%', '100%', '100%', '100%'],
      maxHeight: '100vh'
    }
  },

  acknowledgementsTitle: {
    padding: ['1.75rem', '2rem'],
    paddingLeft: ['', '', '', '31%'],
    '.title': {
      textAlign: 'left',
      fontSize: '2rem'
    }
  },
  acknowledgementsSection1: {
    padding: '0rem 2rem 1rem',
    alignItems: 'flex-start',
    paddingLeft: ['', '', '', '31%'],
    '.section': {
      alignItems: 'flex-start',
      margin: '0rem',
      maxWidth: 'unset'
    },

    '.title': {
      fontSize: '1.5rem',
      opacity: '0.8',
      textAlign: 'left'
    },
    '.text': {
      textAlign: 'left',
      maxWidth: 'unset'
    }
  },
  acknowledgementsSection2: {
    padding: '0rem 2rem 1rem',
    alignItems: 'flex-start',
    paddingLeft: ['', '', '', '31%'],
    '.section': {
      alignItems: 'flex-start',
      margin: '0rem',
      maxWidth: 'unset'
    },

    '.title': {
      fontSize: '1.5rem',
      opacity: '0.8',
      textAlign: 'left'
    },
    '.text': {
      textAlign: 'left',
      maxWidth: 'unset'
    }
  },

  // ? ========================
  // ? ====  Menu page  ====
  // ? ========================
  menuPageHero: {
    height: '80vh',
    width: '100%',
    '.videoContainer': {
      width: '100%',
      video: {
        margin: '0 auto'
      }
    }
  },
  menu: {
    padding: ['0rem', '0rem', '0rem', '0rem'],

    '.innerMenuContainer': {
      padding: '0rem',
      // '> div': {
      //   display: 'flex',
      //   flexDirection: ['', '', '', 'row'],
      // },
      backgroundImage:
        "url('https://res.cloudinary.com/gonation/image/upload/v1622583615/sites/kokomos/Kokomos-Beach-Club-tree.png')",
      backgroundRepeat: 'no-repeat',
      backgroundSize: '50%',
      backgroundPosition: 'center left'
    },

    '.allInContainerWrapper': {
      maxWidth: '1200px',
      margin: '0 auto',
      padding: ['1rem', '0.5rem', '']
    },
    '.tabsRow': {
      // flexDirection: ['', '', '', 'column'],
      padding: '0rem',
      borderTop: 'solid 1px',
      borderColor: 'black',
      marginBottom: ['1rem', '2rem', '3rem']
    },
    '#menuTab': {
      margin: '0rem',
      border: 'none',
      padding: ['0.5rem 1rem', '', '0.75rem 1.25rem'],
      borderBottom: 'solid 1px',
      borderColor: 'black',
      flexGrow: '1',
      maxWidth: 'unset',
      minWidth: ['125px', '', '', '200px'],
      width: 'auto',
      display: 'flex',
      justifyContent: 'center',
      textAlign: 'center',
      fontFamily: 'heading',
      textTransform: 'uppercase',
      color: 'brandBlue'
    },
    '.menuSectionTitle': {
      color: 'brandBlue',
      fontSize: ['1.5rem', '1.75rem', '2rem']
    },
    '.menuSectionDescription': {
      color: 'primary'
    },
    '.menuItemName': {
      marginBottom: '0rem',
      textTransform: 'uppercase',
      color: 'brandBlue'
    },
    '.dropdownContainer': {
      display: 'none'
    }
  },

  // ? ========================
  // ? ====  Gallery page  ====
  // ? ========================

  gallery: {},

  // ? ========================
  // ? ====  Events page  ====
  // ? ========================

  events: {
    '.events-container': {
      maxWidth: 'unset'
    },

    '.special-events-container > .title, .recurring-events-container > .title': {
      display: 'none'
    },
    '.eventItemContent': {
      padding: ['1rem', '', '', '3rem', '4rem']
    },
    '.eventItemImage ': {
      maxHeight: 'unset'
    },

    '.eventItemTitle': {
      marginBottom: '1rem',
      color: 'primary',
      fontSize: ['', '', '', '2rem', '2.5rem']
    },
    '.eventItemDescription': {
      marginBottom: '1rem',
      color: 'brandBlue'
    },
    '.eventTime': {
      color: 'brandBlue'
    },
    '.eventDate': {
      color: 'brandPink'
    },
    '.eventMonth': {
      color: 'secondary'
    },

    '.eventCTABtns': {
      a: {
        variant: 'buttons.primary',
        fontFamily: 'heading'
      }
    },

    '.eventItemBtn ': {
      display: 'none'
    },

    // "event orders"

    '.eventItemContainer': {
      order: '2'
    },
    '.evt-UWf52nT8S1iJx3mCDYq5Sg': {
      order: '1'
    }
  },

  // ? ========================
  // ? ====  Contact page  ====
  // ? ========================

  contactForm: {
    order: '2',
    h2: {
      textTransform: 'uppercase',
      color: 'primary',
      marginBottom: '1rem'
    },
    // '.address, .phone': {
    //   display: 'none',
    // },
    backgroundPosition: 'center center',
    color: 'white',
    padding: ['3rem 1rem', '', '9rem 1rem'],
    '.text': {
      color: 'white'
    },
    '.title': {
      fontSize: ['1.5rem', '1.5rem', '1.7rem', '1.75rem'],
      lineHeight: '1.5',
      color: 'brandPink',
      fontWeight: '800'
    },
    '.inputField, .textField ': {
      fontWeight: '300',
      border: 'none',
      borderBottom: '1px solid',
      borderColor: 'brandOrange',
      borderWidth: '2px',
      borderRadius: '0px',
      padding: '1rem 0.5rem',
      color: 'white',
      backgroundColor: '#3d727bab',
      borderRadius: '5px',
      padding: '1.5rem 1rem',
      '::placeholder': {
        color: 'white'
      }
    }
  },

  locationMap: {
    '.title': {
      fontSize: ['1.25rem', '1.5rem', '1.7rem', '1.75rem'],
      color: 'brandPink',
      fontWeight: '800'
    },

    '.content_container': {
      color: 'brandBlue',
      backgroundImage:
        "url('https://res.cloudinary.com/gonation/image/upload/o_26/v1622126290/sites/kokomos/kokomos-logo_symbol_NO_Text.png.png')",
      backgroundSize: '50%',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'left center'
    }
  },

  // ? ========================
  // ? =====  Beach Club  =====
  // ? ========================

  brochure: {
    marginTop: '2rem',
    '.content': {
      alignItems: 'flex-start',
      width: ['100%', '', '75%']
    },
    '.title': {
      fontSize: ['1.5rem', '1.5rem', '2rem', '3rem'],
      color: 'brandBlue',
      textAlign: 'left'
    },
    '.subtitle': {
      textAlign: 'left',
      fontFamily: 'body',
      color: 'brandOrange'
    },

    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  beachClubMenu: {
    variant: 'customVariants.menu',
    '.membership-packages': {
      '.menuItemsContainer': {
        display: 'flex',

        '.menuItemContainer,.menuItemContainerImgActive': {
          backgroundColor: '#6eb2bdd6',
          width: ['', '', 'calc(33.3% - (0.5rem * 2))'],
          '.menuItemName': {
            marginBottom: '1rem'
          }
        }
      }
    },
    '.membership-benefits': {
      '.menuItemContainerImgActive, .menuItemContainer': {
        width: '100%',
        '.menuItemInnerContainer': {
          flexDirection: ['column', '', 'row']
        },
        '.menuItemImageContainer': {
          maxHeight: '250px',
          maxWidth: '350px'
        },
        img: {
          objectFit: 'contain'
        }
      }
    }
  },

  clubEvents: {
    variant: 'customVariants.menu',
    '.menuItemName': {
      fontFamily: 'heading',
      textTransform: 'uppercase',
      color: 'brandBlue'
    }
  },

  // ? ========================
  // ? ====  Specials menu  ===
  // ? ========================

  specialsHero: {
    '.title': {
      fontSize: ['1.5rem', '2rem', '2.5rem', '3.5rem'],
      fontWeight: 'normal'
    },
    '.hero_content_container': {
      width: '100%',
      margin: '0rem',
      border: 'none'
    }
  },

  // ? ========================
  // ? ====  Cabanas Fall  ====
  // ? ========================

  cabanas: {
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  fallBlock: {
    background: 'unset',
    '.title': {
      marginBottom: '2rem',
      color: 'primary'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  cabanaAlbum: {
    '#': {
      display: 'none'
    },
    '.albumsContainer, .albumName': {
      display: 'none'
    }
  },

  // ? ========================
  // ? ====  Reservations  ====
  // ? ========================

  reservationsSection: {
    variant: 'customVariants.homepageAbout',
    padding: '3rem 1rem',
    minHeight: 'unset'
    // 'div.lazyload-wrapper': {
    //   display: 'none',
    //   '.image': {
    //     display: 'none'
    //   }
    // }
  },
  reservationsSection1: {
    variant: 'customVariants.homepageAbout',
    padding: '3rem 1rem',
    minHeight: 'unset'
    // 'div.lazyload-wrapper': {
    //   display: 'none',
    //   '.image': {
    //     display: 'none'
    //   }
    // }
  }
}
