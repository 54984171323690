export default {
  primary: {
    backgroundColor: '#ce2d4f',
    fontFamily: 'heading',
    color: 'light',
    fontWeight: 'normal',
    backgroundSize: '204% 100%',
    backgroundColor: '#6eb2bd',
    backgroundImage: 'linear-gradient(to left,rgba(0,0,0,0) 50%,#ce2d4f 50%)',
    padding: '1.5rem 3rem',
    fontSize: '1rem',
    textDecoration: 'none',
    textTransform: 'uppercase',
    transition: 'all ease-in-out 1s',
    opacity: '1',
    border: 'none',
    ':hover': {
      // backgroundColor: 'transparent',
      backgroundPosition: '-98% 0%',
      color: 'light'
    }
  },
  secondary: {
    backgroundColor: 'transparent',
    color: 'primary',
    border: 'solid 2px',
    borderColor: 'primary',
    ':hover': {
      backgroundColor: 'primary',
      color: 'white'
    }
  }
}
