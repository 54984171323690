export default {
  text: '#202020',
  primary: '#6eb2bd',
  primaryLight: '#c1e0e3',
  secondary: '#0da57e',
  secondaryLight: '#7dcaab',
  brandOrange: '#f58345',
  brandPink: '#ce2d4f',
  brandBlue: '#023871',
  background: '#FFF',
  backgroundSecondary: '#4a4a4a',
  light: '#FFF',
  dark: '#313131'
}
